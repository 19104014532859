@import '~styles/mixins.sass';

.container {
	box-sizing: border-box;
	@include display-flex-start;
	margin: 8px 0px 25px 0px;
	width: 950px;
	overflow: hidden; }

.seperation-line {
	width: 1px;
	height: 12px;
	margin: 2px 14px; }


