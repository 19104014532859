@import '~styles/mixins.sass';

.card-container {
	cursor: pointer;
	width: 290px;
	height: 195px;
	box-sizing: border-box;
	padding: 11px 0;
	margin: 0 7px 10px 5px; }

.card-cursor {
	cursor: pointer; }

.card-header {
	width: 100%;
	height: 87px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding: 0 12px 3px 12px; }

.card-heading-content {
	width: 85%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	overflow: hidden; }

.card-status-box {
	width: 15%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end; }

.desc-priority-container {
	width: 100%;
	height: 48px;
	overflow: hidden; }

.progress-container {
	width: 100%;
	height: 18px;
	box-sizing: border-box;
	@include display-flex-center-mixin;
	padding: 0 12px 0 12px; }

.progress-bar-background {
	width: 100%;
	height: 3px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 2px; }

.progress-bar {
	max-width: 100%;
	height: 5px;
	border-radius: 4px; }

.story-point-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 15px;
	padding: 0 12px 0 12px;
	box-sizing: border-box; }

.story-point-container-like-customer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	// height: 15px
	padding: 0 12px 0 12px;
	box-sizing: border-box; }

.story-point {
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.like-customer-img {
	width: 24px;
	height: 24px; }

.storypoints-text {
	padding-right: 10px; }

.feature-details-container {
	padding: 0 12px;
	box-sizing: border-box; }

.feature-container {
	padding: 2px 6px 5px 6px;
	box-sizing: border-box; }

.feature-image {
	width: 15px;
	height: 15px;
	display: inline-block;
	vertical-align: middle;
	padding-top: 2px; }

.feature-name {
	padding-left: 5px;
	box-sizing: border-box; }

.feature-desc {
	padding-left: 6px;
	box-sizing: border-box; }

.add-task-button-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end; }

.add-task-button {
	width: 18px;
	height: 18px;
 }	// border-radius: 50%

.priorities-button-container {
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin;
	padding: 0 12px 0 12px;
	box-sizing: border-box; }

.set-priority-heading {
	width: 28%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.priority-buttons {
	width: 72%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between; }

.each-priority {
	padding: 2px 9px;
	border-radius: 16px;
	box-sizing: border-box;
	height: 19px;
	margin-left: 5px; }

.task-priority-container {
	width: 36px;
	height: 18px;
	box-sizing: border-box;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center; }

.task-priority-image {
	width: 10px;
	height: 10px; }

.task-priority-letter {
	padding-left: 3px; }

.blocker-tag-component {
	width: calc(100% - 20px);
	height: 32px;
	@include display-flex-center-mixin;
	padding: 0 10px;
	box-sizing: border-box;
	margin: 0 10px; }

.blocker-image {
	width: 19px;
	height: 19px; }

.blocker-text {
	width: calc(100% - 20px);
	height: 100%;
	padding-left: 7px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: flex-start; }
