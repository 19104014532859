@import '~styles/mixins.sass';

.container {
	height: 100%;
	@include display-flex-center-mixin; }

.wrapper {
	width: 100%; }

.description {
	margin: 25px 100px 20px 100px;
	text-align: center; }

.sprints-container {
	@include display-flex-center-mixin; }

.button-container {
	@include display-flex-center-mixin; }

.walkthrough-background {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0;
	opacity: 0.4; }


