.tab-container {
    display: flex;
    height: 52px;
    border-bottom: 2px solid rgba(35,40,130, 0.2);
    font-size: 18px;
    width: calc(100% - 200px);
    gap: 25px; }

.tab {
    height: 100%;
    width: auto;
    text-align: center;
    cursor: pointer; }

.selected {
    color: #232882;
    font-weight: 600;
    font-size: 18px;
    border-bottom: 4px solid #232882; }

.number {
    padding-top: 20px; }
