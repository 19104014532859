@import '~styles/mixins.sass';

.container {
	box-sizing: border-box; }

.seperation-line {
	width: 1px;
	height: 12px;
	margin: 2px 14px; }


