@import '~styles/mixins.sass';

.container {
	width: 275px;
	height: 290px;
	border-radius: 15px;
	margin-right: 33px; }

.disabled-container {
	zoom: 0.92;
	opacity: 0.2; }

.title-img-container {
	position: relative;
	top: 0;
	left: 0; }

.rotate-image {
	width: 135px;
	height: 135px;
	position: absolute;
	top: 11px;
	left: 15px;
	opacity: 0.5; }

.title {
	text-transform: uppercase;
	padding: 65px 0 0 75px; }

.steps-container {
	margin: 35px 0 0 56px; }
