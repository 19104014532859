@import '~styles/mixins.sass';

.container {
	@include display-flex-start;
	min-width: 200px; }

.number {
	@include display-flex-center-mixin;
	border-radius: 50%;
	width: 24px;
	height: 24px; }

.check-container {
	width: 28px;
	height: 28px; }

.title {
	margin-left: 15px; }
