@import '~styles/mixins.sass';

.metrics-container {
	width: 100%; }

.top-metrics-container {
	@include display-flex-start;
	// padding-right: 45px
 }	// margin-right: 18px

.left-metric-container {
	min-height: 67px;
	border-radius: 18px;
	margin: 0 8px;
	padding: 10px 18px;
	box-sizing: border-box; }

.left-metrics-value-change-container {
	@include display-flex-start;
	margin-top: 5px; }

.metrics-image {
	width: 16px;
	height: 16px;
	margin-right: 8px; }

.bottom-metrics-container {
	@include display-flex-start;
	margin-top: 12px; }

.bottom-metrics-wrapper {
	@include display-flex-start;
	border-radius: 18px;
	padding: 10px 0;
	box-sizing: border-box;
	min-height: 67px;
 }	// margin-left: 8px

.right-metric-container {
	// margin: 0 12px
	padding: 0 20px; }

.metric-title {
	text-transform: uppercase; }

.right-metrics-value-change-container {
	@include display-flex-start; }

.metrics-value {
	margin-right: 12px; }

.metrics-change {
	@include display-flex-center-mixin;
	min-width: 35px;
	min-height: 17px;
	padding: 0 5px;
	border-radius: 12px;
	box-sizing: border-box;
	text-align: center; }

.arrow-container {
	width: 7px;
	height: 9px;
	margin-right: 3px; }

.mvp-title-value-container {
	@include display-flex-start; }

.mvp-value {
	margin-left: 12px; }

.sim-mvp-progress-bar-container {
	width: 100%;
	height: 6px;
	border-radius: 2px;
	@include display-flex-start; }

.sim-mvp-progress-bar {
	height: 6px;
	border-radius: 2px;
	display: flex; }

.game-metric-container {
	// min-height: 67px
	padding: 0 30px;
	box-sizing: border-box; }

.game-metrics-container {
	padding: 10px;
	@include display-flex-center-mixin;
	margin-left: 20px; }
