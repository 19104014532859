@import '~styles/mixins.sass';

.timer-container {
	height: 28px;
	width: 110px;
	@include display-flex-center-mixin; }

.timer-content {
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin;
	padding: 0 10px 5px 10px;
	box-sizing: border-box; }

.timer-content-ar {
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin;
	box-sizing: border-box; }
