@import '~styles/mixins.sass';

.intro-popup-container {
	width: 912px;
	height: 512px;
	box-sizing: border-box; }

.header-container {
	width: 100%;
	height: 56px;
	z-index: 2;
	position: relative;
	top: 0;
	left: 0; }

.header-background-container {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

.title {
	padding: 20px 0 0 25px;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0; }

.close-icon {
	width: 25px;
	height: 25px;
	position: absolute;
	top: 16px;
	right: 25px;
	z-index: 1; }

.content {
	width: 100%;
	height: calc(100% - 56px);
	position: relative; }

.content-background-1 {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0; }

.content-background-2 {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0; }

.intro-content-container {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
	box-sizing: border-box;
	overflow-y: auto;
	padding-top: 370px;
	display: flex;
	flex-direction: column; }

.transcript-container {
	padding: 15px 50px;
	box-sizing: border-box;
	width: 100%; }

.transcript-show {
	padding-top: 5px;
	display: block;
	width: 100%; }

.transcript-hidden {
	padding-top: 5px;
	display: none; }

.transcript {
	cursor: pointer;
	display: flex; }

.video-container {
	// height: 400px
	width: calc(100% - 40px);
	@include display-flex-center-mixin;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 4;
	background: black;
	padding: 20px 0;
	margin: 0 20px;
	box-sizing: border-box;
	overflow-x: hidden;
 }	// box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.1)

.video {
	background-color: black; }

.icon {
	height: 8px;
	width: 12px;
	padding-top: 10px; }

.playing-conditions-container {
	width: 100%;
	box-sizing: border-box;
	padding: 20px 40px;
	// position: absolute
	top: 0;
	left: 0;
 }	// z-index: 4
