@import '~styles/mixins.sass';

.history-item-container {
	width: 100%;
	box-sizing: border-box;
	min-height: 71px;
	padding: 8px 12px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	margin-bottom: 10px;
	max-width: 370px; }

.history-item-header {
	width: 100%;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 5px;
	box-sizing: border-box; }

.history-item-header-text {
	padding: 0 2px; }

.history-item-name-container {
	width: 100%;
	min-height: 20px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 5px; }

.history-item-name {
	width: calc(100% - 19px); }

.history-item-image {
	width: 19px;
	height: 19px; }

.history-item-desc {
	width: 100%;
	text-align: left;
	padding-top: 5px; }
