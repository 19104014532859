@import '~styles/mixins.sass';

.tab-container {
	// min-width: 158px
	min-height: 52px;
	box-sizing: border-box;
	margin: 0 18px;
	text-transform: uppercase;
	border-radius: 54px;
	@include display-flex-center-mixin; }

.selected-tab-container {
	padding: 15px 18px; }
