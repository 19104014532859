@import '~styles/mixins.sass';

.popover-container {
	// position: fixed
	width: 545px;
	min-height: 250px;
	border-radius: 10px;
	// z-index: 10
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	top: 200px; }

@media (max-height: 700px) {
	.popover-container {
		top: 150px; } }

.popover-header {
	@include display-flex-space-between;
	min-height: 61px;
	width: 100%;
	box-sizing: border-box; }

.title-and-description {
	width: 70%;
	margin: 10px 20px; }

.title {
	margin-bottom: 3px; }

.close-container {
	@include display-flex-end;
	width: 20%;
	margin-right: 20px; }

.close-svg {
	width: 26px;
	height: 26px;
	cursor: pointer; }

.choose-options {
	margin: 12px 20px 0 20px; }

.options-container {
	margin: 12px; }

.button-container {
	@include display-flex-center-mixin;
	margin: 24px 0; }

.popover-arrow {
	position: absolute;
	width: 0;
	height: 0; }

.error-message {
	margin: 20px; }
