@import '~styles/mixins.sass';

.leaderboard-popup-container {
	width: 912px;
	height: 512px;
	box-sizing: border-box; }

.header-container {
	width: 100%;
	height: 56px;
	z-index: 2;
	position: relative;
	top: 0;
	left: 0; }

.header-background-container {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

.title {
	padding: 20px 0 0 25px;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0; }

.close-icon {
	width: 25px;
	height: 25px;
	position: absolute;
	top: 16px;
	right: 25px;
	z-index: 1; }

.refresh-container {
	width: 100%;
	height: 60px;
	box-sizing: border-box;
	@include display-flex-end;
	padding-right: 24px; }

.content {
	width: 100%;
	height: calc(100% - 116px);
	position: relative;
	top: 0;
	left: 0; }

.table-header-container {
	width: 100%;
	text-transform: uppercase;
	// @include display-flex-start
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	box-sizing: border-box;
	padding-top: 10px; }

.position {
	min-width: 100px;
	box-sizing: border-box; }

.username {
	min-width: 520px;
	box-sizing: border-box; }

.days-remaining {
	min-width: 145px;
	box-sizing: border-box; }

.mvp {
	min-width: 75px;
	box-sizing: border-box; }

.position-header {
	padding-left: 22px; }

.position-body {
	@include display-flex-center-mixin; }

.mvp-body {
	text-align: right; }

.table-body-container {
	height: calc(100% - 25px);
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden; }

.table-row {
	@include display-flex-horizontal-start;
	padding: 12px 0; }

.seperation-line {
	width: calc(100% - 32px);
	box-sizing: border-box;
	margin: 0 16px;
	height: 1px; }

.background-image {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1; }

.table-container {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%; }
