@import '~styles/mixins.sass';

.objectives-container {
	height: 100%;
	padding: 0 10px 0 5px;
	@include display-flex-center-mixin;
	cursor: pointer; }

.objectives-image-container {
	width: 20px;
	height: 100%;
	@include display-flex-center-mixin; }

.objectives-image {
	width: 18px;
	height: 18px; }

.objectives-text-container {
	padding: 0 20px 0 5px;
	@include display-flex-center-mixin; }
