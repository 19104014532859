.story {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    width: 90%;
    // height: 64px
    padding: 10px 15px;
    margin: 10px;
    box-sizing: border-box; }

.name {
    font-weight: 600;
    font-size: 12px;
    padding: 0px 0px 10px 0px; }

.info {
    font-size: 12px;
    color: rgba(3,26,32,0.5); }

.bottom {
    display: flex;
    justify-content: space-between; }

.priority {
    border: 1px solid #42BAFF;
    box-sizing: border-box;
    border-radius: 20px;
    width: 33px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center; }

.priority_arabic {
    border: 1px solid #42BAFF;
    box-sizing: border-box;
    border-radius: 20px;
    width: 60px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center; }

.priority-image {
    width: 10px;
    height: 10px;
    margin-right: 3px; }

.priority-letter {
    color: #42BAFF;
    font-weight: bold;
    font-size: 10px; }

.wrap {
    overflow: auto;
    width: 100%;
    background-color: rgba(3,26,32, 0.03);
    height: calc(100% - 30px); }

.customers-speak {
    width: 70%; }
