@import '~styles/mixins.sass';

.sim-logo-container {
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.sim-logo {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-right: 30px; }

.sim-name {
	padding-left: 5px; }

.sim-image {
	width: 25px;
	height: 25px; }
