@import '~styles/mixins.sass';

.actions-list-container {
	width: 250px;
	height: 100%;
	// z-index: 1
 }	// overflow-y: auto

.each-action-container {
	margin-bottom: 10px; }

.overlay {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
 }	// z-index: -1
