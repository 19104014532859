@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.button-style {
    @extend %box-sizing;
    @include display-flex-center-mixin;
    min-width: 200px;
    width: fit-content;
    cursor: pointer;
    padding: 5px 10px;
    height: 48px;
    text-transform: uppercase;
    border-radius: 4px; }
