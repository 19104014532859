@import '~styles/mixins.sass';

.container {
	@include display-flex-center-mixin;
	width: 100%;
	min-height: 240px;
	box-sizing: border-box;
	position: relative;
	top: 0;
	left: 0;
	@extend %row; }

.image-container {
	@include display-flex-center-mixin;
	width: 20%;
	height: 100%;
	// padding: 20px 0
	box-sizing: border-box; }

.image {
	width: 268px;
	height: 200px; }

.response-container {
	width: 80%;
	height: 100%;
	padding: 30px 30px 30px 25px;
	box-sizing: border-box; }

.person-name {
	width: 80%;
	height: 100%;
	padding: 0px 30px 20px 15px;
	box-sizing: border-box; }

.response-message {
	width: 100%;
	box-sizing: border-box;
	padding: 7px 190px 10px 0; }

.close-container {
	position: absolute;
	top: 15px;
	right: 15px;
	width: 25px;
	height: 25px; }
