@import '~styles/mixins.sass';

.b-msg {
	padding: 0 15px;
	font-size: 14px;
	color: rgba(3,26,32, 0.6);
	margin-top: 8px; }

.container {
	height: calc(100% - 38px);
	width: 814px;
	box-sizing: border-box;
	background: linear-gradient(180deg, #FFFFFF 0%, #FBF1F4 0.01%, rgba(255, 255, 255, 0.94) 100%);
	font-family: 'Open Sans'; }

.header {
	height: 80px;
	line-height: 80px;
	font-size: 20px;
	font-weight: 600;
	background: linear-gradient(90.49deg, #232882 0%, #572676 100%);
	color: white;
	padding-left: 30px;
	display: flex;
	justify-content: space-between; }
.list {
	height: 100%;
	margin-left: 100px; }
.day {
	position: relative;
	min-height: 100px;
	border-left: 2px solid rgba(35,40,130, 0.2); }
.number {
	position: absolute;
	left: -70px;
	font-size: 14px;
	font-weight: 600;
	color: rgba(35,40,130,0.5); }
.box {
	display: flex;
	overflow: auto;
	height: calc(100% - 135px);
	box-sizing: border-box;
	opacity: 0.95;
	margin-top: 10px; }
.close {
    width: 25px;
    height: 25px;
    margin-top: 23px;
    margin-right: 25px; }

.line {
	width: 100px; }

.circle {
	background: #FFFFFF;
	border: 1px solid rgba(12, 97, 120, 0.36);
	box-sizing: border-box;
	border-radius: 50%;
	height: 17px;
	width: 17px;
	position: absolute;
	left: -9px; }

.action {
	width: 667px;
	// height: 143px
	border-left: 5px solid rgba(255,156,65, 0.7);
	background: #FFFFFF;
	box-sizing: border-box;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09), 0px 3px 14px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	margin-left: 15px;
	position: relative;
	padding: 10px;
	margin-bottom: 15px; }

.event {
	// min-height: 143px
	width: 667px;
	border-left: 5px solid rgba(65,210,255,0.7);
	background: #FFFFFF;
	box-sizing: border-box;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09), 0px 3px 14px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	position: relative;
	margin-left: 15px;
	padding: 10px;
	margin-bottom: 15px; }

.blocker {
	// height: 143px
	width: 240px;
	border-left: 5px solid 	rgba(241,60,60, 0.7);
	background: #FFFFFF;
	box-sizing: border-box;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09), 0px 3px 14px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	margin-left: 15px;
	position: relative;
	padding: 10px;
	margin-bottom: 15px; }

.blocker-msg {
	// height: 143px
	width: 411px;
	background: #FFFFFF;
	box-sizing: border-box;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09), 0px 3px 14px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	margin-left: 15px;
	padding: 10px;
	margin-bottom: 15px; }

.header-tag {
	display: flex;
	align-items: center; }

.spacer {
	padding: 2px 0px;
	display: flex; }
.a-hash {
	font-size: 10px;
	color: rgba(255,156,65,1);
	font-weight: bold; }

.sprint-day-tag {
	padding: 2px 7px;
	background: rgba(2, 26, 32, 0.07);
	color: rgba(2, 26, 32, 0.7);
	font-size: 10px;
	margin-left: 10px;
	border-radius: 8px; }


.e-hash {
	font-size: 10px;
	color: rgba(65,210,255,1);
	font-weight: bold; }
.b-hash {
	font-size: 10px;
	color: rgba(241,60,60, 1);
	font-weight: bold; }
.a-name {
	font-weight: 600;
	font-size: 14px;
	color: rgba(3,26,32,0.9);
	margin-top: 10px; }
.action-option-name {
	font-weight: 600;
	font-size: 12px;
	color: rgba(3,26,32,0.9);
	margin-top: 2px; }
.e-msg {
	font-size: 14px;
	color: rgba(3,26,32, 0.6);
	margin-top: 8px; }
.met-box {
	display: flex;
	margin-top: 10px; }

.met-box-eve {
	margin-top: 15px; }
.metric {
	width: 40px;
	height: 16px;
	border-radius: 40px;
	text-align: center;
	font-weight: 600;
	font-size: 10px;
	line-height: 16px;
	padding: 3px;
	margin: auto; }

.met-name {
	font-size: 9px;
	padding-bottom: 4px;
	color: rgba(3,26,32, 0.7);
	text-align: center;
	text-transform: uppercase; }
.met-container {
	margin-right: 10px; }

.arrow-cont {
	display: flex; }
.arrow {
	height: 10px;
	width: 10px;
	margin:  3px 2px 0px 8px; }
.filter {
	height: 45px;
	display: flex;
	padding: 10px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	div:first-child {
		font-size: 12px;
		color: #031A20;
		opacity: 0.5; } }

.filter-box {
	display: block;
	cursor: pointer;
	border: 0.7px solid rgba(126,190,236,0.7);
	padding: 4px 15px;
	border-radius: 16px;
	margin-left: 10px;
	box-sizing: border-box;
	font-size: 12px;
	@include display-flex-center-mixin; }

.filter-name {
	margin-right: 5px; }

.slected {
	background-color: rgba(48,155,232, 0.6); }
