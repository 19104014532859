@import '~styles/mixins.sass';

.sprint-report-popup-container {
    width: 1300px;
    height: 580px;
    background: white;
    border-radius: 10px;
    overflow: hidden; }

@media only screen and (max-height: 700px) {
    .sprint-report-popup-container {
        height: 550px; } }

.sprint-report-popup-header {
    width: 100%;
    height: calc(100% - 50px); }
