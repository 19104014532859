.legend {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: capitalize;
    color: rgba(3,26,32, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: 80px;
    box-sizing: border-box;
    width: calc(100% - 175px); }

.wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start; }

.graph {
    width: 175px;
    margin-right: 5px;
    box-sizing: border-box; }

.circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 4px; }

.leg-wrap {
    padding-bottom: 7px;
    display: flex; }

.blue {
    background: #232882; }
.pink {
    background: #FF1E50; }
