@import '~styles/mixins.sass';

.main-component {
	width: 814px;
	height: calc(100% - 38px); }

.header-container {
	border-radius: 0;
	padding: 24px 30px 16px 30px;
	display: flex;
	justify-content: space-between; }

.close-icon {
	width: 25px; }

.popup-body {
	padding: 12px 24px;
	height: calc(98% - 70px);
	overflow-x: auto; }

.card-style {
	padding: 20px;
	margin: 16px 0; }

.card-header {
	display: flex;
	justify-content: space-between; }

.tm-member-details {
	display: flex;
	width: 100%;
	margin-bottom: 16px; }

.name-details {
	margin-left: 10px; }

.tm-member-stats {
	display: flex; }

.tm-member-text-details {
	display: flex;
	width: 100%;
	justify-content: space-between; }

.tm-morale-stats {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 40px; }

.tm-skill-stats {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 45px; }

.stat-value {
	padding: 1.5px 12px;
	border-radius: 10px; }

.skill-stats-header {
	display: flex;
	align-items: center; }

.skill-icon {
	width: 11px;
	height: 11px;
	margin-right: 5px; }
