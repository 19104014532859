@import '~styles/mixins.sass';

.action-wrapper {
	position: relative;
	top: 0;
	left: 0; }

.action-container {
	width: 100%;
	height: 32px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	box-sizing: border-box; }

.action-image-container {
	width: 36px;
	height: 32px;
	@include display-flex-center-mixin; }

.action-image {
	width: 20px;
	height: 20px; }

.action-name {
	width: calc(100% - 36px);
	height: 100%;
	box-sizing: border-box;
	padding-left: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.disable-action {
	pointer-events: none;
	opacity: 0.2; }


