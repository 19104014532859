@import '~styles/mixins.sass';

.history-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-left: 25px; }

.history-content {
	@include display-flex-center-mixin;
	width: 135px;
	height: 46px;
	cursor: pointer; }

.history-name {
	padding-left: 5px;
	cursor: pointer; }

.history-image {
	width: 17px;
	height: 16px; }
