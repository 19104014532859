body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: white
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* width */
::-webkit-scrollbar {
	width: 8px;
	border-radius: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f6f5f7;
	border-radius: 8px;
	box-shadow: inset 0 0 2px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.3);
	border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.6);
}

.flex-box-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.base-app-box {
	width: 100%;
	height: 100vh;
	min-width: 1270px;
	min-height: 600px;
	/* -webkit-transform:translate3d(0,0,0); */
	position: relative;
	overflow: hidden;
	-webkit-touch-callout: none;
	/* iOS Safari*/
	-webkit-user-select: none;
	-webkit-user-drag: none;
	/* Chrome/Safari/Opera*/
	-khtml-user-select: none;
	-khtml-user-drag: none;
	/* Konqueror*/
	-moz-user-select: none;
	-moz-user-drag: none;
	/* Firefox*/
	-ms-user-select: none;
	-ms-user-drag: none;
	/* Internet Explorer/Edge*/
	user-select: none;
}


/* React joyride tooltip */
.react-joyride__spotlight {
	/* --padding: 8px 8px; */
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	/* padding: var(--padding); */
	background-clip: content-box;
	box-shadow: inset 0 0 0 var(--padding) rgba(0, 0, 0, 0);
	box-sizing: border-box;
	background-color: #FD1E510C;
}

.react-joyride__tooltip {
	font-size: 16px !important;
	width: auto !important;
	max-width: 700px !important;
	padding: 0 5px !important;
	box-shadow: 2px 2px 4px 0px #00000033 !important;
	background-image: url('https://knolskape-website.s3.amazonaws.com/misc/suyog_patil/2019/07/18/73/rectangle_4.4.svg') !important;
	background-size: cover;

}

/* .react-joyride__tooltip div:nth-child(1) {text-align: left !important;	
}

.react-joyride__tooltip div:nth-child(1) div {
	padding: 10px !important;
	padding-bottom: 20px !important;
}*/

.react-joyride__tooltip div:nth-child(2) {
	margin-top: 0px !important;
	padding-bottom: 10px
}

.react-joyride__tooltip div:nth-child(2) button[data-test-id="button-primary"] {
	padding: 7px 34px !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 22px !important;
	color: #232882 !important;
	margin-right: 8px !important;
	border-radius: 0px !important;
	background: -webkit-linear-gradient(341.41deg, #FFFFFF 3.7%, #FFFFFF 100%) !important;
	background: linear-gradient(108.59deg, #FFFFFF 3.7%, #FFFFFF 100%) !important;
	opacity: 0.9 !important;
	border: 1px solid #232882 !important;
}

.react-joyride__tooltip div:nth-child(2) button[data-test-id="button-back"] {
	/* display: none !important */
	padding: 7px 34px !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 22px !important;
	color: #232882 !important;
	margin-right: 8px !important;
	border-radius: 0px !important;
	background: -webkit-linear-gradient(341.41deg, #FFFFFF 3.7%, #FFFFFF 100%) !important;
	background: linear-gradient(108.59deg, #FFFFFF 3.7%, #FFFFFF 100%) !important;
	opacity: 0.9 !important;
	border: 1px solid #232882 !important;
}

.react-joyride__overlay {
	cursor: default !important;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.loader_mainComponent__1PEa_ {
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

.desktopHeader_header-container__18rM8 {
  box-sizing: border-box;
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 20px;
  padding-left: 20px; }

@media only screen and (min-width: 1280px) {
  .desktopHeader_header-content__2O0H2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1600px) {
  .desktopHeader_header-content__2O0H2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1920px) {
  .desktopHeader_header-content__2O0H2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.desktopHeader_header-container__18rM8 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  min-width: 1280px;
  position: relative; }

.desktopHeader_header-content__2O0H2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  height: 100%; }

.desktopHeader_left-header__3YcZJ {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 0; }

.desktopHeader_middle-header__2WdKT {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0; }

.desktopHeader_right-header__jncQ8 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.simLogo_sim-logo-container__UmMzT {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.simLogo_sim-logo__1QZxK {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 30px; }

.simLogo_sim-name__2VHuk {
  padding-left: 5px; }

.simLogo_sim-image__1mtbo {
  width: 25px;
  height: 25px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.headerSeparation_separation__17m7Y {
  width: 2px;
  height: 24px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.simUserProfile_user-profile-container-with-options__2r3RC {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: none;
  padding-left: 20px; }

.simUserProfile_user-profile-container__2oqXy {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.simUserProfile_user-profile-image-container__Am_V2 {
  width: 24px;
  height: 24px; }

.simUserProfile_user-profile-arrow-container__3EPiJ {
  margin-left: 5px;
  width: 8px;
  height: 6px; }

.simUserProfile_user-profile-option-container__Rz9t- {
  position: absolute;
  width: 200px;
  z-index: 50;
  top: 35px;
  left: -160px;
  overflow: hidden; }

.simUserProfile_each-option__n3ILc {
  width: 100%;
  padding: 5px 20px;
  box-sizing: border-box; }

.simUserProfile_user-name-container__rJHw3 {
  width: 100%;
  box-sizing: border-box;
  padding: 5px 20px; }

.simUserProfile_user-name__3KTpA {
  width: 100%;
  text-align: left;
  padding: 5px 0;
  word-break: break-all; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.userProfile_main-component__7peiL {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.upArrow_main-component__3lndQ {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.downArrow_main-component__2dOO2 {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.timer_timer-container__y4EvL {
  height: 28px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center; }

.timer_timer-content__20FXp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 5px 10px;
  box-sizing: border-box; }

.timer_timer-content-ar__Kypzo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.simObjectives_objectives-container__2bgGD {
  height: 100%;
  padding: 0 10px 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.simObjectives_objectives-image-container__7hXh8 {
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.simObjectives_objectives-image__3UAu2 {
  width: 18px;
  height: 18px; }

.simObjectives_objectives-text-container__3HyCG {
  padding: 0 20px 0 5px;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.objectives_main-component__8gcfU {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.simLeaderBoard_leaderboard-container__13W-a {
  height: 100%;
  margin: 0 10px 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.simLeaderBoard_leaderboard-image-container__2_dKb {
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.simLeaderBoard_leaderboard-image__fe1xh {
  width: 16px;
  height: 16px; }

.simLeaderBoard_leaderboard-text-container__3d3Px {
  padding: 0 20px 0 5px;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.leaderboard_main-component__2Zr1k {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.simMVP_sim-mvp-container__js3jf {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding-left: 15px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative; }

.simMVP_mvp-down-arrow__35qtw {
  position: absolute;
  width: 100%;
  height: 10px;
  background: rgba(255, 255, 255, 0.5);
  top: 100%;
  left: 0;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: simMVP_showDownArrow__11EGK 0.5s 1;
  animation: simMVP_showDownArrow__11EGK 0.5s 1; }

.simMVP_down-arrow__2OdBL {
  width: 10px;
  height: 5px; }

.simMVP_sim-mvp-content__2n6Lu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 20px;
  height: 100%;
  box-sizing: border-box; }

.simMVP_sim-mvp-details__3nIn0 {
  width: 170px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box; }

.simMVP_sim-mvp-top__3FT7z {
  width: 100%;
  height: 60%;
  margin-top: 3px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  box-sizing: border-box; }

.simMVP_sim-mvp-text__13-ej {
  padding-right: 5px; }

.simMVP_sim-mvp-value__-z1jb {
  padding-left: 5px; }

.simMVP_sim-mvp-bottom__34s2J {
  width: 100%;
  height: 41%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 2px;
  box-sizing: border-box; }

.simMVP_sim-mvp-arrow-container__37-MZ {
  padding: 0 0 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; }

.simMVP_sim-mvp-arrow__2YU5Q {
  width: 5px;
  height: 5px; }

.simMVP_sim-mvp-progress-bar-container__2kU4U {
  width: 100%;
  height: 7px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2px;
  overflow: hidden; }

.simMVP_sim-mvp-progress-bar__3NNcE {
  height: 7px;
  border-radius: 3px;
  display: flex; }

@-webkit-keyframes simMVP_showDownArrow__11EGK {
  from {
    top: 80%;
    opacity: 0; }
  to {
    top: 100%;
    opacity: 1; } }

@keyframes simMVP_showDownArrow__11EGK {
  from {
    top: 80%;
    opacity: 0; }
  to {
    top: 100%;
    opacity: 1; } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.infoComponent_info-container__3SGQ5 {
  width: 12px;
  height: 12px;
  position: relative;
  padding-right: 2px; }

.infoComponent_info-icon__1vyNf {
  width: 100%;
  height: 100%; }

.infoComponent_tooltip-container-right__C4Tv9 {
  width: 250px;
  position: absolute;
  top: -5px;
  left: 20px;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: flex-start; }

.infoComponent_tooltip-container-left__2hmkS {
  width: 250px;
  position: absolute;
  top: -5px;
  left: -250px;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: flex-start; }

.infoComponent_tooltip-container-top__2KEqg {
  width: 250px;
  position: absolute;
  bottom: 100%;
  left: calc(50% - 125px);
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

@media only screen and (max-height: 760px) {
  .infoComponent_tooltip-container-top__2KEqg {
    width: 220px;
    left: calc(50% - 110px); } }

.infoComponent_tooltip-container-bottom__jlYHT {
  width: 250px;
  position: absolute;
  top: 18px;
  left: -116px;
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.infoComponent_tooltip-arrow-left-right__Dn8RV {
  width: 0;
  height: 0;
  margin-top: 8px; }

.infoComponent_tooltip-arrow-top-bottom__2OfbE {
  width: 0;
  height: 0; }

.infoComponent_tooltip-content__3ai9a {
  box-sizing: border-box;
  padding: 7px;
  width: calc(100% - 7px);
  text-align: left; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.infoIcon_main-component__3WBY9 {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.simProgress_sim-progress-container__2Yjtb {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  box-sizing: border-box; }

.simProgress_sim-progress-content__Liy22 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 25px;
  margin-top: 5px; }

.simProgress_progress-start-text__3SaRK {
  padding-right: 5px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.simProgress_progress-end-text__3AwJk {
  padding-left: 5px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px; }

.simProgress_progress-container__3S11g {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  padding: 0 10px; }

.simProgress_end-days-container__NbTB6 {
  width: 2px;
  height: 10px;
  margin-right: 1px;
  border-radius: 2px;
  box-sizing: border-box; }

.simProgress_middle-days-container__2iusD {
  width: 2px;
  height: 6px;
  margin-right: 1px;
  border-radius: 2px;
  box-sizing: border-box; }

.simProgress_current-days-container__34-c9 {
  width: 2px;
  height: 8px;
  margin-right: 1px;
  border-radius: 2px;
  box-sizing: border-box; }

.simProgress_day-tag-container__2CewB {
  position: absolute;
  width: 45px;
  height: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.desktopFooter_footer-container__21xME {
  width: 100%;
  height: 22px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center; }

.desktopFooter_sim-name__3r-2u {
  padding: 0 4px; }

.desktopFooter_sim-tag__3o9bB {
  padding: 0 4px; }

.desktopFooter_knolskape-name__1oC9Y {
  padding: 0 2px 0 4px; }

.desktopFooter_knolskape-logo__2DikT {
  padding: 0 2px 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.desktopFooter_copyright__3BX4f {
  padding: 0 4px; }

.desktopFooter_footer-bar__1bDXf {
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.planningWalkthrough_container__10YDr {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.planningWalkthrough_wrapper__3oa-4 {
  width: 750px; }

.planningWalkthrough_description__1P7qb {
  text-align: center; }

.planningWalkthrough_description-steps-container__1BDQ2 {
  padding: 25px 70px;
  margin: 40px 0 67px 0;
  text-align: center; }

.planningWalkthrough_steps-container__Ei984 {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center; }

.planningWalkthrough_button-container__vidiM {
  display: flex;
  align-items: center;
  justify-content: center; }

.planningWalkthrough_walkthrough-background__p5UbP {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.4; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.walkthroughTabs_tabs-container__l0sZa {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.walkthroughTabs_tab-line-container__1t6Ga {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.walkthroughTabs_tab-seperation-line__1bWKJ {
  width: 134px;
  height: 1px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.walkthroughTab_tab-container__3MUnU {
  min-height: 52px;
  box-sizing: border-box;
  margin: 0 18px;
  text-transform: uppercase;
  border-radius: 54px;
  display: flex;
  align-items: center;
  justify-content: center; }

.walkthroughTab_selected-tab-container__ifP1g {
  padding: 15px 18px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.walkthroughSteps_container__2E47m {
  box-sizing: border-box; }

.walkthroughSteps_seperation-line__2VGzk {
  width: 1px;
  height: 12px;
  margin: 2px 14px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.walkthroughStep_container__1gGVY {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 200px; }

.walkthroughStep_number__2LaV5 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px; }

.walkthroughStep_check-container__2acqn {
  width: 28px;
  height: 28px; }

.walkthroughStep_title__3Dwl_ {
  margin-left: 15px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.selectedTaskButtonHollow_main-component__23z7Z {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.filledButton_button-style__1BNN2 {
  box-sizing: border-box; }

.filledButton_button-style__1BNN2 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  padding: 5px 10px;
  height: 48px;
  text-transform: uppercase;
  border-radius: 4px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.executionWalkthrough_container__22bTU {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.executionWalkthrough_wrapper__17XgO {
  width: 100%; }

.executionWalkthrough_description__1rfwD {
  margin: 25px 100px 20px 100px;
  text-align: center; }

.executionWalkthrough_sprints-container__wuCb1 {
  display: flex;
  align-items: center;
  justify-content: center; }

.executionWalkthrough_button-container__333zt {
  display: flex;
  align-items: center;
  justify-content: center; }

.executionWalkthrough_walkthrough-background__NJBOB {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.4; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.sprintsWalkthrough_container__BWDgi {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 8px 0px 25px 0px;
  width: 950px;
  overflow: hidden; }

.sprintsWalkthrough_seperation-line__3gSY_ {
  width: 1px;
  height: 12px;
  margin: 2px 14px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.sprintWalkthrough_container__xlMlc {
  width: 275px;
  height: 290px;
  border-radius: 15px;
  margin-right: 33px; }

.sprintWalkthrough_disabled-container__1LLlv {
  zoom: 0.92;
  opacity: 0.2; }

.sprintWalkthrough_title-img-container__YHy40 {
  position: relative;
  top: 0;
  left: 0; }

.sprintWalkthrough_rotate-image__2nFXN {
  width: 135px;
  height: 135px;
  position: absolute;
  top: 11px;
  left: 15px;
  opacity: 0.5; }

.sprintWalkthrough_title__3L9eV {
  text-transform: uppercase;
  padding: 65px 0 0 75px; }

.sprintWalkthrough_steps-container__y5g7R {
  margin: 35px 0 0 56px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.bundleLoader_container__2FRBD {
  width: 100vw;
  height: calc(100vh - 52px);
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.overlay_complete-container__3WQ2d {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 50; }

.overlay_bottom-container__BVvxh {
  display: flex;
  align-items: flex-end;
  justify-content: center; }

.overlay_bottom-container-component__AeQ1G {
  min-height: 100px;
  width: 100%; }

.overlay_right-container__xgyic {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start; }

.overlay_center-container__1FKny {
  display: flex;
  justify-content: center;
  align-items: center; }

.overlay_right-container-component__autOD {
  height: 100%;
  min-width: 500px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start; }

.actionResponse_container__2zxJB {
  box-sizing: border-box;
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 20px;
  padding-left: 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.actionResponse_container__2zxJB {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 240px;
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0; }

.actionResponse_image-container__2MeWi {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
  box-sizing: border-box; }

.actionResponse_image__33Ij- {
  width: 268px;
  height: 200px; }

.actionResponse_response-container__2Ei9B {
  width: 80%;
  height: 100%;
  padding: 30px 30px 30px 25px;
  box-sizing: border-box; }

.actionResponse_person-name__nAsRe {
  width: 80%;
  height: 100%;
  padding: 0px 30px 20px 15px;
  box-sizing: border-box; }

.actionResponse_response-message__1OvGt {
  width: 100%;
  box-sizing: border-box;
  padding: 7px 190px 10px 0; }

.actionResponse_close-container__2k1zF {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  height: 25px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.close_main-component__8AZL1 {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.actionResponseMetrics_metrics-container__1Hvwm {
  width: 100%; }

.actionResponseMetrics_top-metrics-container__24DkA {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionResponseMetrics_left-metric-container__1Jvod {
  min-height: 67px;
  border-radius: 18px;
  margin: 0 8px;
  padding: 10px 18px;
  box-sizing: border-box; }

.actionResponseMetrics_left-metrics-value-change-container__2Y4MP {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px; }

.actionResponseMetrics_metrics-image__3ha0D {
  width: 16px;
  height: 16px;
  margin-right: 8px; }

.actionResponseMetrics_bottom-metrics-container__CyS1Y {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px; }

.actionResponseMetrics_bottom-metrics-wrapper__32Sd- {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 18px;
  padding: 10px 0;
  box-sizing: border-box;
  min-height: 67px; }

.actionResponseMetrics_right-metric-container__1jxND {
  padding: 0 20px; }

.actionResponseMetrics_metric-title__2T5fK {
  text-transform: uppercase; }

.actionResponseMetrics_right-metrics-value-change-container__1Y2w8 {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionResponseMetrics_metrics-value__2rXCU {
  margin-right: 12px; }

.actionResponseMetrics_metrics-change__3I9NN {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 35px;
  min-height: 17px;
  padding: 0 5px;
  border-radius: 12px;
  box-sizing: border-box;
  text-align: center; }

.actionResponseMetrics_arrow-container__3QmTJ {
  width: 7px;
  height: 9px;
  margin-right: 3px; }

.actionResponseMetrics_mvp-title-value-container__2cSl6 {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionResponseMetrics_mvp-value__3V0m1 {
  margin-left: 12px; }

.actionResponseMetrics_sim-mvp-progress-bar-container__1IWq2 {
  width: 100%;
  height: 6px;
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionResponseMetrics_sim-mvp-progress-bar__3-xBK {
  height: 6px;
  border-radius: 2px;
  display: flex; }

.actionResponseMetrics_game-metric-container__SZEFx {
  padding: 0 30px;
  box-sizing: border-box; }

.actionResponseMetrics_game-metrics-container__x3UzV {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.incrementArrow_main-component__BHm9Y {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.decrementArrow_main-component__1rDig {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.flagIcon_main-component__2jbE3 {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.sprintMetrics_left-metric-container__2iuxN {
  min-height: 67px;
  border-radius: 18px;
  margin-right: 8px;
  padding: 6px 18px;
  box-sizing: border-box; }

.sprintMetrics_right-arrow__2Ysxz {
  margin: 0 6px;
  width: 8px;
  height: 10px; }

.sprintMetrics_sprint-day-container__3Oh2s {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase; }

.sprintMetrics_sprint-blocks-container__tL0MY {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.sprintMetrics_sprint-block__2qhUb {
  width: 8px;
  height: 24px;
  border-radius: 7px;
  margin: 3px; }

.sprintMetrics_sprint-block__2qhUb:first-child {
  margin-left: 0; }

.sprintMetrics_current-sprint-block__IE2gl {
  width: 11px;
  height: 31px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.rightArrow_mainComponent__1uUwh {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.event_container__uU7N4 {
  box-sizing: border-box;
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 20px;
  padding-left: 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.event_container__uU7N4 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 240px;
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0; }

.event_image-container__LwNI- {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
  box-sizing: border-box; }

.event_image__2pTEr {
  width: 250px;
  height: 182px; }

.event_response-container__rrptf {
  width: 80%;
  padding: 30px 30px 30px 13px;
  box-sizing: border-box; }

.event_response-message__1tuew {
  width: 100%;
  box-sizing: border-box;
  padding-right: 190px; }

.event_person-name__IA14Z {
  width: 80%;
  height: 100%;
  padding: 0px 30px 20px 15px;
  box-sizing: border-box; }

.event_close-container__2obTF {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  height: 25px; }

.event_metrics-container__1Vs5F {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px; }

.event_left-metric-container__36XTp {
  min-height: 67px;
  border-radius: 18px;
  margin: 0 8px;
  padding: 10px 18px;
  box-sizing: border-box;
  min-width: 175px; }

.event_left-metrics-value-change-container__jHn37 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3px; }

.event_metric-title__2SEZW {
  text-transform: uppercase; }

.event_metrics-image__1_8ed {
  width: 16px;
  height: 16px;
  margin-right: 8px; }

.event_metrics-value__2fZpS {
  margin-right: 12px; }

.event_metrics-change__zaD2j {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 35px;
  height: 17px;
  padding: 0 5px;
  border-radius: 12px;
  box-sizing: border-box; }

.event_arrow-container__-aM55 {
  width: 7px;
  height: 9px;
  margin-right: 3px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.endSprintConfirmation_main-component__2d5ds {
  width: 541px;
  height: 249px;
  border-radius: 10px;
  overflow: hidden; }

.endSprintConfirmation_popup-header-container__3ghoN {
  width: 100%;
  height: 147px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.endSprintConfirmation_popup-header-container-content__2XKR1 {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px; }

.endSprintConfirmation_popup-header-container-background__1IoCf {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.endSprintConfirmation_popup-details-container__ASLCZ {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.endSprintConfirmation_popup-buttons-container__1VMxo {
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.endSprintConfirmation_button-container__S1r6O {
  padding: 0 10px; }

.endSprintConfirmation_header-image__1F_yD {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.endSprintConfirmation_header-text__2maEE {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0; }

.endSprintConfirmation_header-desc__18QrT {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.hollowButton_button-style__3Yv-s {
  box-sizing: border-box; }

.hollowButton_button-style__3Yv-s {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 195px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  padding: 5px 10px;
  height: 48px;
  text-transform: uppercase;
  border-radius: 4px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.triangleBackground_main-component__NBxks {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.autofillSprintConfirmation_main-component__2O7Pn {
  width: 541px;
  height: 249px;
  border-radius: 10px;
  overflow: hidden; }

.autofillSprintConfirmation_popup-header-container__Ir2Xp {
  width: 100%;
  height: 147px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.autofillSprintConfirmation_popup-header-container-content__2JNyK {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px; }

.autofillSprintConfirmation_popup-header-container-background__f9tr- {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.autofillSprintConfirmation_popup-details-container__2kPOL {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.autofillSprintConfirmation_popup-buttons-container__Bt1SB {
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.autofillSprintConfirmation_button-container__3QsIc {
  padding: 0 10px; }

.autofillSprintConfirmation_header-image__2fY8c {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.autofillSprintConfirmation_header-text__Yv4Vw {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0; }

.autofillSprintConfirmation_header-desc__PDbMp {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.replanSprintConfirmation_main-component__2ggpn {
  width: 541px;
  height: 249px;
  border-radius: 10px;
  overflow: hidden; }

.replanSprintConfirmation_popup-header-container__1dF-N {
  width: 100%;
  height: 147px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.replanSprintConfirmation_popup-header-container-content__Yidtl {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px; }

.replanSprintConfirmation_popup-header-container-background__5CoWd {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.replanSprintConfirmation_popup-details-container__14Uyo {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.replanSprintConfirmation_popup-buttons-container__3ugd3 {
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.replanSprintConfirmation_button-container__3kagy {
  padding: 0 10px; }

.replanSprintConfirmation_header-image__3hJV4 {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.replanSprintConfirmation_header-text__1mY0v {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0; }

.replanSprintConfirmation_header-desc__2KmAH {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.replanSprintHelpPopup_main-component__2vBDY {
  width: 541px;
  height: 249px;
  border-radius: 10px;
  overflow: hidden; }

.replanSprintHelpPopup_popup-header-container__2L8Na {
  width: 100%;
  height: 147px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.replanSprintHelpPopup_popup-header-container-content__1TOlP {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px; }

.replanSprintHelpPopup_popup-header-container-background__VKMZK {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.replanSprintHelpPopup_popup-details-container__6r-d6 {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.replanSprintHelpPopup_popup-buttons-container__HcCa0 {
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.replanSprintHelpPopup_button-container__2JQMK {
  padding: 0 10px; }

.replanSprintHelpPopup_header-image__3vxnZ {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.replanSprintHelpPopup_header-text__1J6nB {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0; }

.replanSprintHelpPopup_header-desc__V3TnZ {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.addTaskConfirmation_main-component__RuHhC {
  width: 541px;
  border-radius: 10px;
  overflow: hidden; }

.addTaskConfirmation_popup-header-container__q9-8M {
  width: 100%;
  min-height: 147px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.addTaskConfirmation_popup-header-container-content__3WhO7 {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px; }

.addTaskConfirmation_popup-header-container-background__2XP1L {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.addTaskConfirmation_popup-details-container__2WPBG {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.addTaskConfirmation_popup-buttons-container__WBmAO {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  box-sizing: border-box; }

.addTaskConfirmation_button-container__WA0Ps {
  padding: 0 10px; }

.addTaskConfirmation_header-image__D1jtg {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.addTaskConfirmation_header-text__1LWoY {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0;
  word-break: break-all; }

.addTaskConfirmation_header-desc__2pssE {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

.addTaskConfirmation_details-container__3gFku {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0 10px 0; }

.addTaskConfirmation_tasks-container__1uze4 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.addTaskConfirmation_storyPoints-container__tZAjZ {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.storyPopup_story-popup-container__2wIBM {
  width: 912px; }

.storyPopup_header-container__1VZ7L {
  width: 100%;
  min-height: 90px;
  position: relative; }

.storyPopup_content-container__Dl-h3 {
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 20px; }

.storyPopup_header-background-container__2DkI6 {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.storyPopup_header-content-container__1qlRD {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px; }

.storyPopup_header-details-container__4-CiD {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box; }

.storyPopup_story-name__ltuFf {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.storyPopup_story-details__1M47i {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }

.storyPopup_close-container__JmGF5 {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.storyPopup_close-icon__3FHr4 {
  width: 26px;
  height: 26px; }

.storyPopup_each-detail-container__3uMpS {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 30px; }

.storyPopup_each-detail-name__LzJpu {
  padding-right: 5px; }

.storyPopup_each-detail-value__192tC {
  padding-right: 10px; }

.storyPopup_each-detail-info__3bKjj {
  width: 10px;
  height: 10px; }

.storyPopup_story-description-container__3hnRt {
  width: 70%;
  height: 35%;
  box-sizing: border-box;
  padding: 10px 24px; }

.storyPopup_story-history-container__vWDGp {
  width: 70%;
  height: 50%;
  box-sizing: border-box;
  padding: 24px; }

.storyPopup_story-description-container-full__182Y8 {
  width: 100%;
  height: 35%;
  box-sizing: border-box;
  padding: 10px 24px; }

.storyPopup_story-history-container-full__Mepwg {
  width: 100%;
  height: 50%;
  box-sizing: border-box;
  padding: 24px; }

.storyPopup_story-details-container__eG6uy {
  width: 70%;
  height: 20%;
  box-sizing: border-box;
  padding: 10px 24px; }

.storyPopup_story-actions-container__RJCpb {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 0 10px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  overflow-y: auto; }

.storyPopup_story-description-heading__2D45y {
  width: 100%;
  text-align: left;
  padding-bottom: 10px; }

.storyPopup_story-description-content__23ySz {
  width: 100%;
  text-align: left; }

.storyPopup_feature-description-heading__1_mgY {
  width: 100%;
  text-align: left;
  padding-bottom: 10px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.storyPopup_feature-description-content__2SgYA {
  width: 100%;
  text-align: left;
  padding-bottom: 10px; }

.storyPopup_story-history-heading__17M3J {
  width: 100%;
  text-align: left;
  padding-bottom: 10px;
  height: 16px; }

.storyPopup_story-history-content__12peX {
  width: 100%;
  height: 100%;
  overflow-y: auto; }

.storyPopup_feature-image__1GA3Q {
  width: 20px;
  height: 20px;
  margin-right: 10px; }

.storyPopup_selection-container__3Xan6 {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  margin-top: 10px; }

.storyPopup_selection-content__3w9wT {
  cursor: pointer;
  height: 36px;
  box-sizing: border-box;
  padding: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.storyPopup_checkbox-container__25bjX {
  width: 18px;
  height: 18px; }

.storyPopup_text-container__kzI6S {
  padding-left: 4px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.storyHistoryItem_history-item-container__28zvz {
  width: 100%;
  box-sizing: border-box;
  min-height: 71px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 10px;
  max-width: 370px; }

.storyHistoryItem_history-item-header__2TRjt {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  box-sizing: border-box; }

.storyHistoryItem_history-item-header-text__2r6oz {
  padding: 0 2px; }

.storyHistoryItem_history-item-name-container__Yd2UB {
  width: 100%;
  min-height: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 5px; }

.storyHistoryItem_history-item-name__1qHi2 {
  width: calc(100% - 19px); }

.storyHistoryItem_history-item-image__1KNPv {
  width: 19px;
  height: 19px; }

.storyHistoryItem_history-item-desc__dzQGO {
  width: 100%;
  text-align: left;
  padding-top: 5px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.blocker_main-component__1uOj0 {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.actionList_actions-list-container__1hMLc {
  width: 250px;
  height: 100%; }

.actionList_each-action-container__2woja {
  margin-bottom: 10px; }

.actionList_overlay__2VJTI {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.action_action-wrapper__meILv {
  position: relative;
  top: 0;
  left: 0; }

.action_action-container__3xeVk {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box; }

.action_action-image-container__19ch- {
  width: 36px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center; }

.action_action-image__1HgNq {
  width: 20px;
  height: 20px; }

.action_action-name__3RoJj {
  width: calc(100% - 36px);
  height: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.action_disable-action__k467P {
  pointer-events: none;
  opacity: 0.2; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.actionOptionsPopover_popover-container__31Nl0 {
  width: 545px;
  min-height: 250px;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  top: 200px; }

@media (max-height: 700px) {
  .actionOptionsPopover_popover-container__31Nl0 {
    top: 150px; } }

.actionOptionsPopover_popover-header__2KkzY {
  display: flex;
  justify-content: space-between;
  min-height: 61px;
  width: 100%;
  box-sizing: border-box; }

.actionOptionsPopover_title-and-description__3ORCF {
  width: 70%;
  margin: 10px 20px; }

.actionOptionsPopover_title__TyLv3 {
  margin-bottom: 3px; }

.actionOptionsPopover_close-container__tugAb {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
  margin-right: 20px; }

.actionOptionsPopover_close-svg__24iRI {
  width: 26px;
  height: 26px;
  cursor: pointer; }

.actionOptionsPopover_choose-options__W7XuT {
  margin: 12px 20px 0 20px; }

.actionOptionsPopover_options-container__1vnOv {
  margin: 12px; }

.actionOptionsPopover_button-container__2598J {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0; }

.actionOptionsPopover_popover-arrow__YYvxb {
  position: absolute;
  width: 0;
  height: 0; }

.actionOptionsPopover_error-message__3S3AU {
  margin: 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.actionOption_option-container__2IVnM {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 6px 10px;
  margin-bottom: 10px;
  cursor: pointer; }

.actionOption_radio-container__1wzzq {
  width: 16px;
  margin-top: 2px; }

.actionOption_option-details-container__2NuDZ {
  width: 100%; }

.actionOption_radio-outline__wFxiP {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-sizing: border-box;
  margin-top: 5px; }

.actionOption_radio-inner-circle__39d4j {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  box-sizing: border-box; }

.actionOption_option-effort-cost-container__1Eyi8 {
  display: flex;
  justify-content: space-between; }

.actionOption_option-effort-cost-content__2diZw {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionOption_option-effort-cost-title__2t305 {
  margin-right: 5px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.teamLunch_main-component__1X6iJ {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.standup_main-component__2wLty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.marketPulseSurvey_main-component__1-TQE {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.rebuildFeature_main-component__1nDtu {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.replanSprint_main-component__UdGH9 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.retrospect_main-component__3tNJ- {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.teamMotivationSurvey_main-component__2a1kZ {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.updatePrd_main-component__2f7Du {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.userFeedback_main-component__2IWjp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.userResearch_main-component__Hwjdv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.training_main-component__3oDAS {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.meetTheTeam_main-component__zg8u5 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.mom_main-component__2pJyR {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.talkOnAgile_main-component__7TsJE {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.addTaskCheckBox_main-component__1nSgK {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.selectedTaskCheckBox_main-component__1G8iY {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.simMVPPopup_mvp-popup-container__3YDd7 {
  width: 1000px; }

.simMVPPopup_header-container__2bBVx {
  width: 100%;
  min-height: 150px;
  position: relative; }

@media only screen and (max-height: 700px) {
  .simMVPPopup_header-container__2bBVx {
    min-height: 100px; } }

.simMVPPopup_content-container__34cbS {
  width: 100%;
  height: 450px;
  position: relative;
  box-sizing: border-box;
  padding: 30px 20px; }

.simMVPPopup_cards-container__3PPRb {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start; }

.simMVPPopup_header-background-container__3ZbJF {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.simMVPPopup_header-content-container__3mlsW {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
  box-sizing: border-box; }

@media only screen and (max-height: 760px) {
  .simMVPPopup_header-content-container__3mlsW {
    padding: 10px 15px; } }

.simMVPPopup_header-details-container__3OSde {
  width: 90%;
  height: 100%;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box; }

.simMVPPopup_heading-content__249J5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 5px; }

.simMVPPopup_desc-content__rXGoO {
  width: 100%;
  text-align: left; }

.simMVPPopup_heading-text__2HcAO {
  padding-right: 10px;
  box-sizing: border-box; }

.simMVPPopup_correct-help-container__3VJH8 {
  display: flex;
  align-items: center;
  justify-content: center; }

.simMVPPopup_correct-help-img__p3CqX {
  width: 24px;
  height: 24px; }

.simMVPPopup_correct-help-text__wyBIW {
  padding-left: 8px; }

.simMVPPopup_close-container__3uJCf {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 5px 0;
  box-sizing: border-box; }

.simMVPPopup_close-icon__xQLLe {
  width: 26px;
  height: 26px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.taskCard_card-container__2yLfR {
  cursor: pointer;
  width: 290px;
  height: 195px;
  box-sizing: border-box;
  padding: 11px 0;
  margin: 0 7px 10px 5px; }

.taskCard_card-cursor__311N5 {
  cursor: pointer; }

.taskCard_card-header__1Y9FW {
  width: 100%;
  height: 87px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 12px 3px 12px; }

.taskCard_card-heading-content__3pTMn {
  width: 85%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden; }

.taskCard_card-status-box__LFpXM {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.taskCard_desc-priority-container__2xrEj {
  width: 100%;
  height: 48px;
  overflow: hidden; }

.taskCard_progress-container__2E-Xx {
  width: 100%;
  height: 18px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px 0 12px; }

.taskCard_progress-bar-background__2wDg1 {
  width: 100%;
  height: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 2px; }

.taskCard_progress-bar__1TWK_ {
  max-width: 100%;
  height: 5px;
  border-radius: 4px; }

.taskCard_story-point-container__3bmku {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 15px;
  padding: 0 12px 0 12px;
  box-sizing: border-box; }

.taskCard_story-point-container-like-customer__2zd_d {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 12px 0 12px;
  box-sizing: border-box; }

.taskCard_story-point__3HHZX {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.taskCard_like-customer-img__3JjLE {
  width: 24px;
  height: 24px; }

.taskCard_storypoints-text__1Inld {
  padding-right: 10px; }

.taskCard_feature-details-container__fHJTp {
  padding: 0 12px;
  box-sizing: border-box; }

.taskCard_feature-container__2MP0I {
  padding: 2px 6px 5px 6px;
  box-sizing: border-box; }

.taskCard_feature-image__3RFIn {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  padding-top: 2px; }

.taskCard_feature-name__3zGUN {
  padding-left: 5px;
  box-sizing: border-box; }

.taskCard_feature-desc__1izci {
  padding-left: 6px;
  box-sizing: border-box; }

.taskCard_add-task-button-container__1Qqzq {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end; }

.taskCard_add-task-button__1AS79 {
  width: 18px;
  height: 18px; }

.taskCard_priorities-button-container__3VvxN {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px 0 12px;
  box-sizing: border-box; }

.taskCard_set-priority-heading__13W0A {
  width: 28%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.taskCard_priority-buttons__1XCfJ {
  width: 72%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.taskCard_each-priority__2qOin {
  padding: 2px 9px;
  border-radius: 16px;
  box-sizing: border-box;
  height: 19px;
  margin-left: 5px; }

.taskCard_task-priority-container__2yv45 {
  width: 36px;
  height: 18px;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center; }

.taskCard_task-priority-image__rN9Rw {
  width: 10px;
  height: 10px; }

.taskCard_task-priority-letter__3wokj {
  padding-left: 3px; }

.taskCard_blocker-tag-component__GJZZT {
  width: calc(100% - 20px);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  box-sizing: border-box;
  margin: 0 10px; }

.taskCard_blocker-image__pomyV {
  width: 19px;
  height: 19px; }

.taskCard_blocker-text__1_D3r {
  width: calc(100% - 20px);
  height: 100%;
  padding-left: 7px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.addTaskButton_main-component__1x8wB {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.selectedTaskButton_main-component__TPCSz {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.lowPriority_main-component__3g09v {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.mediumPriority_main-component__36Kk_ {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.highPriority_main-component__17rvN {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.blockerResolved_main-component__UQ5jq {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.customerLike_main-component__wqUb4 {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

.sprintReport_sprint-report-container__19LFg {
  box-sizing: border-box;
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 20px;
  padding-left: 20px; }

@media only screen and (min-width: 1280px) {
  .sprintReport_sprint-report-content-container__gRfBP, .sprintReport_sprint-report-popup-content-container__3PKhA {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1600px) {
  .sprintReport_sprint-report-content-container__gRfBP {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .sprintReport_sprint-report-popup-content-container__3PKhA {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1920px) {
  .sprintReport_sprint-report-content-container__gRfBP {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .sprintReport_sprint-report-popup-content-container__3PKhA {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.sprintReport_oa__1Qxol {
  overflow: auto; }

.sprintReport_unfinshed-task__2KEkf {
  width: 50%;
  height: 100%; }

.sprintReport_task-graph__18nd5 {
  width: 50%; }

.sprintReport_sprint-report-container__19LFg {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  justify-content: center;
  font-family: 'Open Sans'; }

.sprintReport_master__10V3d {
  background-size: cover;
  background-image: url("/static/media/sim_background.ac319c29.png"); }

.sprintReport_sprint-report-component__Y42dM {
  width: 100%;
  height: 100%; }

.sprintReport_tabs__hQ4Z- {
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.sprintReport_text__3ZKV1 {
  width: 100%;
  font-family: 'Open Sans';
  padding: 20px 20px;
  margin: 15px 0px;
  width: 100%;
  color: #031a20;
  line-height: 25px;
  background-color: rgba(253, 30, 81, 0.06);
  box-sizing: border-box;
  border-radius: 8px; }
  .sprintReport_text__3ZKV1 div:first-child {
    font-size: 18px;
    font-weight: 600; }
  .sprintReport_text__3ZKV1 div:nth-child(2) {
    font-size: 16px;
    margin-top: 15px;
    color: #031A20;
    opacity: 0.7; }

.sprintReport_info__U32TV {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px 0;
  width: 100%; }

.sprintReport_metrics__2oq7b {
  width: 43%;
  box-sizing: border-box;
  padding-right: 15px; }

.sprintReport_met-name__2xfO7 {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #232882;
  opacity: 0.6; }

.sprintReport_customers-speak__2UIv5 {
  width: 90%; }

.sprintReport_task__JHEaP {
  width: 57%;
  box-sizing: border-box;
  padding-left: 15px;
  border-left: 1px solid rgba(35, 40, 130, 0.2); }

.sprintReport_task-row__LKLqs {
  display: flex;
  width: 100%;
  height: 250px;
  margin-bottom: 20px; }

.sprintReport_heading__2bGLm {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: rgba(35, 40, 130, 0.6);
  height: 30px;
  display: flex;
  align-items: center; }

.sprintReport_heading-text__LrvFn {
  padding-right: 10px; }

.sprintReport_game-completed__264P9 {
  background: -webkit-linear-gradient(359.87deg, #232882 0%, #B32161 100%);
  background: linear-gradient(90.13deg, #232882 0%, #B32161 100%);
  width: 100%;
  height: 250px;
  border-radius: 5px;
  display: flex; }

.sprintReport_img__3in3o {
  width: 400px;
  height: 100%; }

.sprintReport_content__2PQw1 {
  padding: 51px 10px 0px 10px; }

.sprintReport_title__3zdGB {
  font-weight: 600;
  font-family: 'Open Sans';
  font-size: 20px;
  color: rgba(255, 255, 255, 0.9); }

.sprintReport_ms__2vMAx {
  font-family: 'Open Sans';
  font-size: 16px;
  color: rgba(255, 255, 255, 0.9);
  padding: 10px 0px 20px 0px;
  max-width: 80%; }

.sprintReport_download-button__2xgNq {
  background: -webkit-linear-gradient(335.32deg, #FFFFFF 3.7%, #FFFFFF 100%);
  background: linear-gradient(114.68deg, #FFFFFF 3.7%, #FFFFFF 100%);
  opacity: 0.9;
  border: 1px solid #232882;
  border-radius: 4px;
  width: 220px;
  height: 46px;
  color: #232882;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  line-height: 46px;
  cursor: pointer; }

.sprintReport_walkthrough-tabs-container__1uMnw {
  padding: 22px 0; }

.sprintReport_flow__3ZfZ0 {
  position: relative; }

.sprintReport_buttons-container__2dOWE {
  height: 75px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }

.sprintReport_single-button-container__Hap1W {
  margin-right: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; }

.sprintReport_help-text-report__3E3bF {
  height: 20px;
  padding-top: 4px;
  width: 300px; }

.tabs_tab-container__AFKqV {
  display: flex;
  height: 52px;
  border-bottom: 2px solid rgba(35, 40, 130, 0.2);
  font-size: 18px;
  width: calc(100% - 200px);
  grid-gap: 25px;
  gap: 25px; }

.tabs_tab__fKFcR {
  height: 100%;
  width: auto;
  text-align: center;
  cursor: pointer; }

.tabs_selected__1gBO- {
  color: #232882;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 4px solid #232882; }

.tabs_number__3apYX {
  padding-top: 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.metric_big__1xCxF {
  width: 530px;
  height: 92px;
  background: #FFFFFF;
  box-shadow: 0px 4px 5px rgba(11, 93, 115, 0.25);
  border-radius: 8px;
  margin: 10px 0px 10px 10px;
  position: relative;
  border: 1px solid #232882;
  box-sizing: border-box; }

.metric_name-container__1EIG4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 0px 0px 8px; }

.metric_name__2dJq9 {
  color: rgba(35, 40, 130, 0.9);
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  padding-right: 5px; }

.metric_completion__6vEri {
  color: #232882;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  padding: 0px 0px 0px 8px; }

.metric_graph-box__17vEA {
  padding: 5px 0px 0px 8px; }

.metric_graph__3ggTh {
  background: #232882;
  opacity: 0.1;
  border-radius: 2px;
  height: 4px;
  width: 380px; }

.metric_graph-overlay__nwbKg {
  background: rgba(35, 40, 130, 0.9);
  border-radius: 2px;
  height: 4px;
  margin-top: -4px; }

.metric_small__1KZeJ {
  height: 70px;
  background: #FFFFFF;
  box-shadow: 0px 4px 13px rgba(11, 93, 115, 0.25);
  border-radius: 8px;
  width: 170px;
  margin-left: 10px;
  margin-top: 10px;
  position: relative; }

.metric_mb__2tPrS {
  height: 100px; }
  .metric_mb__2tPrS .metric_b-change__1O5F1 {
    bottom: 10px; }

.metric_small-boxes__2G5h1 {
  display: flex;
  margin-bottom: 35px; }

.metric_big-box__3BeZv {
  display: flex; }

.metric_met-name__3tKRw {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #232882;
  opacity: 0.6; }

.metric_arrow__Jv4I8 {
  width: 12px;
  height: 6px;
  padding-top: 2px;
  padding-right: 3px; }

.metric_arrow-small__1DxFj {
  width: 12px;
  height: 6px;
  padding-top: 2px;
  padding-right: 3px; }

.metric_b-change-small__9JCiX {
  display: flex;
  position: absolute;
  bottom: 14px;
  right: 8px;
  font-size: 10px;
  color: rgba(35, 40, 130, 0.7);
  display: flex;
  align-items: center;
  justify-content: center; }

.metric_b-change__1O5F1 {
  display: flex;
  position: absolute;
  bottom: 27px;
  right: 20px;
  font-size: 10px;
  color: rgba(35, 40, 130, 0.7);
  display: flex;
  align-items: center;
  justify-content: center; }

.metric_re-box__3HPZc {
  background: rgba(255, 30, 80, 0.04);
  border-radius: 8px;
  width: 196px;
  height: 62px;
  margin-left: 10px;
  margin-top: 10px; }

.metric_re-completion__mFuKS {
  font-size: 18px;
  line-height: 25px;
  color: rgba(35, 40, 130, 0.9);
  font-weight: bold;
  padding: 6px 0px 0px 14px; }

.metric_re-name-container__Fltov {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 0px 0px 14px; }

.metric_re-name__XNMpl {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: rgba(35, 40, 130, 0.8);
  opacity: 0.8;
  padding-right: 5px; }

.metric_border-red__eeMa_ {
  border-bottom: 4px solid #D63228;
  box-sizing: border-box; }

.metric_border-green__r1C29 {
  border-bottom: 4px solid #36B496;
  box-sizing: border-box; }

.stories_story__3sqlj {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  width: 90%;
  padding: 10px 15px;
  margin: 10px;
  box-sizing: border-box; }

.stories_name__2LhmE {
  font-weight: 600;
  font-size: 12px;
  padding: 0px 0px 10px 0px; }

.stories_info__1t42x {
  font-size: 12px;
  color: rgba(3, 26, 32, 0.5); }

.stories_bottom__2Q6gZ {
  display: flex;
  justify-content: space-between; }

.stories_priority__2JIQ- {
  border: 1px solid #42BAFF;
  box-sizing: border-box;
  border-radius: 20px;
  width: 33px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center; }

.stories_priority_arabic__3rr92 {
  border: 1px solid #42BAFF;
  box-sizing: border-box;
  border-radius: 20px;
  width: 60px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center; }

.stories_priority-image__1y5YF {
  width: 10px;
  height: 10px;
  margin-right: 3px; }

.stories_priority-letter__3TzHu {
  color: #42BAFF;
  font-weight: bold;
  font-size: 10px; }

.stories_wrap__202Lr {
  overflow: auto;
  width: 100%;
  background-color: rgba(3, 26, 32, 0.03);
  height: calc(100% - 30px); }

.stories_customers-speak__3jJIL {
  width: 70%; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.cust_comment__1h552 {
  width: calc(100% - 50px);
  font-size: 14px;
  line-height: 25px;
  color: rgba(3, 26, 32, 0.7);
  min-height: 34px; }

.cust_heading__rld5v {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: rgba(35, 40, 130, 0.6); }

.cust_top__29r5d {
  display: flex;
  margin-bottom: 5px; }

.cust_avatar-wrap__10Vhh {
  margin-left: 28px;
  margin-top: -6px; }
  .cust_avatar-wrap__10Vhh img {
    height: 35px;
    width: 35px; }

.cust_comment-container__2jzHF {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(35, 40, 120, 0.2);
  padding: 5px 0; }

.cust_comment-image__goanh {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  margin: 2px 10px 0 10px;
  position: relative; }

.cust_normal-chat-image__2uXpa {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: -7px;
  left: -12px; }

.completion_legend__dxfNS {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: capitalize;
  color: rgba(3, 26, 32, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-top: 80px;
  box-sizing: border-box;
  width: calc(100% - 175px); }

.completion_wrap__WRMVQ {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }

.completion_graph__WyLnC {
  width: 175px;
  margin-right: 5px;
  box-sizing: border-box; }

.completion_circle__3mWgb {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 4px; }

.completion_leg-wrap__3s4ot {
  padding-bottom: 7px;
  display: flex; }

.completion_blue__3OEZq {
  background: #232882; }

.completion_pink__1riGu {
  background: #FF1E50; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.circularProgressBar_bar-container__1GXo1 {
  width: 100%;
  height: 100%; }

.circularProgressBar_progressbar-container__2xMqA {
  position: relative; }

.circularProgressBar_progress-number__3yhwY {
  font-size: 28px;
  position: absolute;
  width: 100%;
  top: 35%;
  left: 20%;
  padding: 0;
  margin: 0; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.gameComplete_main-component__3J45h {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.history_history-container__UsQt5 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 25px; }

.history_history-content__1L4hV {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  height: 46px;
  cursor: pointer; }

.history_history-name__3gjgY {
  padding-left: 5px;
  cursor: pointer; }

.history_history-image__2ceB0 {
  width: 17px;
  height: 16px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.historySvg_main-component__210Ti {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.sendEmailReport_email-report-container__3Djxu {
  width: 240px;
  margin-right: 20px; }

.sendEmailReport_email-heading__3dOGh {
  width: 100%;
  padding-bottom: 4px; }

.sendEmailReport_email-input-container__3kXMk {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box; }

.sendEmailReport_email-input__1d9uz {
  width: calc(100% - 25px);
  height: 100%; }

.sendEmailReport_email-send__2xBSQ {
  width: 25px;
  height: 25px; }

.sendEmailReport_email-input-element__2ribP {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  color: white; }

.sendEmailReport_email-input-element__2ribP:focus {
  outline: none; }

.sendEmailReport_email-status-bar__m7_4K {
  width: 100%;
  padding-top: 4px;
  box-sizing: border-box; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.emailSend_main-component__1b4s0 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.sprintReportPopupContainer_sprint-report-popup-container__1BIoC {
  width: 1300px;
  height: 580px;
  background: white;
  border-radius: 10px;
  overflow: hidden; }

@media only screen and (max-height: 700px) {
  .sprintReportPopupContainer_sprint-report-popup-container__1BIoC {
    height: 550px; } }

.sprintReportPopupContainer_sprint-report-popup-header__274ft {
  width: 100%;
  height: calc(100% - 50px); }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.sprintReportPopupHeader_header-container__Ps_9G {
  width: 100%;
  min-height: 50px;
  position: relative;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  overflow: hidden; }

.sprintReportPopupHeader_header-background-container__hrafQ {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.sprintReportPopupHeader_header-content-container__QYSZq {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.sprintReportPopupHeader_header-details-container__KJNTn {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box; }

.sprintReportPopupHeader_story-name__3_u3X {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.sprintReportPopupHeader_close-container__3ZI9k {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.sprintReportPopupHeader_close-icon__10RFE {
  width: 26px;
  height: 26px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.productLaunchPopup_main-component__4MDeK {
  width: 541px;
  min-height: 249px;
  border-radius: 10px;
  overflow: hidden; }

.productLaunchPopup_popup-header-container__fejRL {
  width: 100%;
  height: 147px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.productLaunchPopup_popup-header-container-content__VA1bT {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px; }

.productLaunchPopup_popup-header-container-background__2tKUx {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.productLaunchPopup_popup-details-container__180_P {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.productLaunchPopup_popup-buttons-container__2qyaw {
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.productLaunchPopup_button-container__3Q1IE {
  padding: 0 10px; }

.productLaunchPopup_header-image__hfmGP {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.productLaunchPopup_header-text__3NMHP {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0; }

.productLaunchPopup_header-desc__1crpE {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

.productLaunchPopup_popup-desc-container__13Hz9 {
  width: 100%;
  min-height: 20px;
  box-sizing: border-box;
  padding: 20px 20px 0 20px;
  text-align: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.leaderboardPopup_leaderboard-popup-container__37FIS {
  width: 912px;
  height: 512px;
  box-sizing: border-box; }

.leaderboardPopup_header-container__ag-d1 {
  width: 100%;
  height: 56px;
  z-index: 2;
  position: relative;
  top: 0;
  left: 0; }

.leaderboardPopup_header-background-container__3wuIA {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.leaderboardPopup_title__1RIYd {
  padding: 20px 0 0 25px;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0; }

.leaderboardPopup_close-icon__3xr7j {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 16px;
  right: 25px;
  z-index: 1; }

.leaderboardPopup_refresh-container__3T4tM {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px; }

.leaderboardPopup_content__kLQKS {
  width: 100%;
  height: calc(100% - 116px);
  position: relative;
  top: 0;
  left: 0; }

.leaderboardPopup_table-header-container__3fepF {
  width: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-top: 10px; }

.leaderboardPopup_position__1mlTz {
  min-width: 100px;
  box-sizing: border-box; }

.leaderboardPopup_username__2iex2 {
  min-width: 520px;
  box-sizing: border-box; }

.leaderboardPopup_days-remaining__27hoj {
  min-width: 145px;
  box-sizing: border-box; }

.leaderboardPopup_mvp__2iuAx {
  min-width: 75px;
  box-sizing: border-box; }

.leaderboardPopup_position-header__1Fi4W {
  padding-left: 22px; }

.leaderboardPopup_position-body__30C7O {
  display: flex;
  align-items: center;
  justify-content: center; }

.leaderboardPopup_mvp-body__sMXxc {
  text-align: right; }

.leaderboardPopup_table-body-container__24JLI {
  height: calc(100% - 25px);
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden; }

.leaderboardPopup_table-row__26Boy {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0; }

.leaderboardPopup_seperation-line__3Yz2v {
  width: calc(100% - 32px);
  box-sizing: border-box;
  margin: 0 16px;
  height: 1px; }

.leaderboardPopup_background-image__2fsjK {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; }

.leaderboardPopup_table-container__19VbN {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.knowYourTeam_main-component__3DDh4 {
  width: 814px;
  height: calc(100% - 38px); }

.knowYourTeam_header-container__1fch4 {
  border-radius: 0;
  padding: 24px 30px 16px 30px;
  display: flex;
  justify-content: space-between; }

.knowYourTeam_close-icon__2aEIi {
  width: 25px; }

.knowYourTeam_popup-body__D_8mt {
  padding: 12px 24px;
  height: calc(98% - 70px);
  overflow-x: auto; }

.knowYourTeam_card-style__2ReZ4 {
  padding: 20px;
  margin: 16px 0; }

.knowYourTeam_card-header__1dQRe {
  display: flex;
  justify-content: space-between; }

.knowYourTeam_tm-member-details__2Rjp2 {
  display: flex;
  width: 100%;
  margin-bottom: 16px; }

.knowYourTeam_name-details__2zZZc {
  margin-left: 10px; }

.knowYourTeam_tm-member-stats__TRyax {
  display: flex; }

.knowYourTeam_tm-member-text-details__2zlq3 {
  display: flex;
  width: 100%;
  justify-content: space-between; }

.knowYourTeam_tm-morale-stats__1k5U8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 40px; }

.knowYourTeam_tm-skill-stats__3Xpk2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 45px; }

.knowYourTeam_stat-value__1dFP- {
  padding: 1.5px 12px;
  border-radius: 10px; }

.knowYourTeam_skill-stats-header__wWM1F {
  display: flex;
  align-items: center; }

.knowYourTeam_skill-icon__16fzA {
  width: 11px;
  height: 11px;
  margin-right: 5px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.skill_main-component__XYDrJ {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.morale_main-component__22stP {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.knowYourCustomers_main-component__1i2Rg {
  width: 814px;
  height: calc(100% - 38px);
  position: relative; }

.knowYourCustomers_header-container__2e9t1 {
  border-radius: 0;
  padding: 24px 30px 16px 30px;
  display: flex;
  box-sizing: border-box;
  height: 70px;
  justify-content: space-between; }

.knowYourCustomers_close-icon__12--2 {
  width: 25px; }

.knowYourCustomers_popup-body__2mgqK {
  padding: 12px 24px;
  height: calc(100% - 240px);
  overflow: auto;
  box-sizing: border-box; }

.knowYourCustomers_card-style__1M8Qu {
  padding: 20px;
  margin: 16px 0; }

.knowYourCustomers_card-header__2h06T {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px; }

.knowYourCustomers_tm-member-details__xJy1p {
  display: flex; }

.knowYourCustomers_name-details__qeSmO {
  margin-left: 10px; }

.knowYourCustomers_footer-container__njkd0 {
  display: flex;
  align-items: center;
  bottom: 10px;
  padding: 5px 15px;
  box-sizing: border-box;
  height: 170px; }

.knowYourCustomers_footer-image__35pXz {
  width: 54px;
  height: 54px;
  min-width: 54px;
  margin-right: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; }

.knowYourCustomers_cpo-details__dTinv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.kycIcon_main-component__1H7AC {
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.actionLoader_svg-container__NMFFG {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.actionLoader_svg-wrapper__1zi06 {
  width: 57px;
  height: 57px;
  margin-bottom: 10px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.eventLoader_loader-container__qJFY4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.eventLoader_svg-container__350E0 {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: eventLoader_loadingMove__2U5OG 1.5s infinite;
  animation: eventLoader_loadingMove__2U5OG 1.5s infinite;
  width: 103px;
  height: 92px;
  margin-bottom: 10px; }

.eventLoader_svg-wrapper__1e_jt {
  width: 100%;
  height: 100%; }

@-webkit-keyframes eventLoader_loadingMove__2U5OG {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  10% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg); }
  20% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg); }
  30% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes eventLoader_loadingMove__2U5OG {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  10% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg); }
  20% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg); }
  30% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.introPopup_intro-popup-container__K2AE0 {
  width: 912px;
  height: 512px;
  box-sizing: border-box; }

.introPopup_header-container__1RpFa {
  width: 100%;
  height: 56px;
  z-index: 2;
  position: relative;
  top: 0;
  left: 0; }

.introPopup_header-background-container__1qvuJ {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.introPopup_title__ZNnsk {
  padding: 20px 0 0 25px;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0; }

.introPopup_close-icon__1Ny6_ {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 16px;
  right: 25px;
  z-index: 1; }

.introPopup_content__3cpAE {
  width: 100%;
  height: calc(100% - 56px);
  position: relative; }

.introPopup_content-background-1__1nsj2 {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0; }

.introPopup_content-background-2__1H19X {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0; }

.introPopup_intro-content-container__1cfR5 {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  box-sizing: border-box;
  overflow-y: auto;
  padding-top: 370px;
  display: flex;
  flex-direction: column; }

.introPopup_transcript-container__3t_UU {
  padding: 15px 50px;
  box-sizing: border-box;
  width: 100%; }

.introPopup_transcript-show__2SHBL {
  padding-top: 5px;
  display: block;
  width: 100%; }

.introPopup_transcript-hidden__2C2jl {
  padding-top: 5px;
  display: none; }

.introPopup_transcript__2yf9d {
  cursor: pointer;
  display: flex; }

.introPopup_video-container__2M_nV {
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background: black;
  padding: 20px 0;
  margin: 0 20px;
  box-sizing: border-box;
  overflow-x: hidden; }

.introPopup_video__37A5M {
  background-color: black; }

.introPopup_icon__1QHDd {
  height: 8px;
  width: 12px;
  padding-top: 10px; }

.introPopup_playing-conditions-container__3Gdvl {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 40px;
  top: 0;
  left: 0; }

.playingConditionsBox_playing-conditions__1U3Lp {
  min-width: 600px;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  box-sizing: border-box;
  margin: 10px 0; }

.playingConditionsBox_playing-conditions-title__aLhwM {
  display: flex;
  padding-left: 25px;
  padding-top: 25px;
  padding-bottom: 10px; }

.playingConditionsBox_playing-conditions-desc__5HGUd {
  text-align: justify; }
  .playingConditionsBox_playing-conditions-desc__5HGUd li {
    padding: 5px; }

.playingConditionsBox_each-condition__3H-tX {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 25px; }

.playingConditionsBox_each-condition-image__3e40S {
  margin-top: 4px;
  width: 18px;
  height: 18px; }

.playingConditionsBox_each-condition-text__2n3HC {
  width: calc(100% - 18px);
  box-sizing: border-box;
  padding-left: 14px;
  text-align: left; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.kVideoPlayer_video-container__1LQWE {
  width: 100%;
  height: 100%; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

.history_b-msg__4NU79 {
  padding: 0 15px;
  font-size: 14px;
  color: rgba(3, 26, 32, 0.6);
  margin-top: 8px; }

.history_container__K2F9Y {
  height: calc(100% - 38px);
  width: 814px;
  box-sizing: border-box;
  background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), color-stop(0.01%, #FBF1F4), to(rgba(255, 255, 255, 0.94)));
  background: -webkit-linear-gradient(top, #FFFFFF 0%, #FBF1F4 0.01%, rgba(255, 255, 255, 0.94) 100%);
  background: linear-gradient(180deg, #FFFFFF 0%, #FBF1F4 0.01%, rgba(255, 255, 255, 0.94) 100%);
  font-family: 'Open Sans'; }

.history_header__1VqO1 {
  height: 80px;
  line-height: 80px;
  font-size: 20px;
  font-weight: 600;
  background: -webkit-linear-gradient(359.51deg, #232882 0%, #572676 100%);
  background: linear-gradient(90.49deg, #232882 0%, #572676 100%);
  color: white;
  padding-left: 30px;
  display: flex;
  justify-content: space-between; }

.history_list__3DLSI {
  height: 100%;
  margin-left: 100px; }

.history_day__3Q-Z6 {
  position: relative;
  min-height: 100px;
  border-left: 2px solid rgba(35, 40, 130, 0.2); }

.history_number__1KZ2X {
  position: absolute;
  left: -70px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(35, 40, 130, 0.5); }

.history_box__2UfPl {
  display: flex;
  overflow: auto;
  height: calc(100% - 135px);
  box-sizing: border-box;
  opacity: 0.95;
  margin-top: 10px; }

.history_close__3MFd9 {
  width: 25px;
  height: 25px;
  margin-top: 23px;
  margin-right: 25px; }

.history_line__3xc74 {
  width: 100px; }

.history_circle__1rQzv {
  background: #FFFFFF;
  border: 1px solid rgba(12, 97, 120, 0.36);
  box-sizing: border-box;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  position: absolute;
  left: -9px; }

.history_action__1Q2M4 {
  width: 667px;
  border-left: 5px solid rgba(255, 156, 65, 0.7);
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09), 0px 3px 14px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-left: 15px;
  position: relative;
  padding: 10px;
  margin-bottom: 15px; }

.history_event__3rhMF {
  width: 667px;
  border-left: 5px solid rgba(65, 210, 255, 0.7);
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09), 0px 3px 14px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: relative;
  margin-left: 15px;
  padding: 10px;
  margin-bottom: 15px; }

.history_blocker__lVpoD {
  width: 240px;
  border-left: 5px solid rgba(241, 60, 60, 0.7);
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09), 0px 3px 14px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-left: 15px;
  position: relative;
  padding: 10px;
  margin-bottom: 15px; }

.history_blocker-msg__kYVID {
  width: 411px;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09), 0px 3px 14px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-left: 15px;
  padding: 10px;
  margin-bottom: 15px; }

.history_header-tag__3VCdv {
  display: flex;
  align-items: center; }

.history_spacer__1hbjy {
  padding: 2px 0px;
  display: flex; }

.history_a-hash__2CBXZ {
  font-size: 10px;
  color: #ff9c41;
  font-weight: bold; }

.history_sprint-day-tag__18YH0 {
  padding: 2px 7px;
  background: rgba(2, 26, 32, 0.07);
  color: rgba(2, 26, 32, 0.7);
  font-size: 10px;
  margin-left: 10px;
  border-radius: 8px; }

.history_e-hash__28vhW {
  font-size: 10px;
  color: #41d2ff;
  font-weight: bold; }

.history_b-hash__3dlga {
  font-size: 10px;
  color: #f13c3c;
  font-weight: bold; }

.history_a-name__28RH1 {
  font-weight: 600;
  font-size: 14px;
  color: rgba(3, 26, 32, 0.9);
  margin-top: 10px; }

.history_action-option-name__1B-aL {
  font-weight: 600;
  font-size: 12px;
  color: rgba(3, 26, 32, 0.9);
  margin-top: 2px; }

.history_e-msg__2Ah8T {
  font-size: 14px;
  color: rgba(3, 26, 32, 0.6);
  margin-top: 8px; }

.history_met-box__MntvQ {
  display: flex;
  margin-top: 10px; }

.history_met-box-eve__2Ytan {
  margin-top: 15px; }

.history_metric__1apRJ {
  width: 40px;
  height: 16px;
  border-radius: 40px;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  padding: 3px;
  margin: auto; }

.history_met-name__SI6qF {
  font-size: 9px;
  padding-bottom: 4px;
  color: rgba(3, 26, 32, 0.7);
  text-align: center;
  text-transform: uppercase; }

.history_met-container__2on8j {
  margin-right: 10px; }

.history_arrow-cont__1qmDt {
  display: flex; }

.history_arrow__5wqM4 {
  height: 10px;
  width: 10px;
  margin: 3px 2px 0px 8px; }

.history_filter__2NPN6 {
  height: 45px;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center; }
  .history_filter__2NPN6 div:first-child {
    font-size: 12px;
    color: #031A20;
    opacity: 0.5; }

.history_filter-box__6mv4V {
  display: block;
  cursor: pointer;
  border: 0.7px solid rgba(126, 190, 236, 0.7);
  padding: 4px 15px;
  border-radius: 16px;
  margin-left: 10px;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center; }

.history_filter-name__qWpya {
  margin-right: 5px; }

.history_slected__M0Gdx {
  background-color: rgba(48, 155, 232, 0.6); }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

