@import '~styles/mixins.sass';

.leaderboard-container {
	height: 100%;
	margin: 0 10px 0 5px;
	@include display-flex-center-mixin;
	cursor: pointer; }

.leaderboard-image-container {
	width: 20px;
	height: 100%;
	@include display-flex-center-mixin; }

.leaderboard-image {
	width: 16px;
	height: 16px; }

.leaderboard-text-container {
	padding: 0 20px 0 5px;
	@include display-flex-center-mixin; }
