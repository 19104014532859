.playing-conditions {
    min-width: 600px;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    box-sizing: border-box;
    margin: 10px 0; }

.playing-conditions-title {
    display: flex;
    padding-left: 25px;
    padding-top: 25px;
    padding-bottom: 10px; }

.playing-conditions-desc {
    text-align: justify;
    li {
        padding: 5px; } }

.each-condition {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px 25px; }

.each-condition-image {
    margin-top: 4px;
    width: 18px;
    height: 18px; }

.each-condition-text {
    width: calc(100% - 18px);
    box-sizing: border-box;
    padding-left: 14px;
    text-align: left; }
