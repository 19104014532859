@import '~styles/mixins.sass';

.svg-container {
	width: 100%;
	@include display-flex-center-mixin; }

.svg-wrapper {
	width: 57px;
	height: 57px;
	margin-bottom: 10px; }
